import VeeValidate, { Configuration, Rule, Validator } from "vee-validate";
Vue.use(VeeValidate);
import Vue from "vue";
import { JWTStore } from "./store/modules";

// Configuration for VeeValidate to prevent collision with elementUI validation
const config: Configuration = {
  fieldsBagName: "vFields",
  errorBagName: "vErrors"
};

VeeValidate.configure(config);

VeeValidate.Validator.extend("checkUserExists", {
  /** message is taken from traslation files */
  validate: async (value: any, args: any[]) => {
    if (value) {
      await JWTStore.userexists(value);
      return !JWTStore.UserExists;
    }
    return false;
  }
} as Rule);
